<template>
  <div class="ml_recharge">
    <body style="tab-interval: 36pt">
      <!--StartFragment-->
      <div class="Section0" style="layout-grid: 18pt">
        <p
          class="MsoNormal"
          align="center"
          style="
            margin-top: 18.05pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 1gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: center;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 22px;
              "
              ><font face="PingFang SC Semibold">产品协议</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 22px;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 18.05pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 1gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 18px;
              "
              ><font face="PingFang SC Semibold">套餐说明</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 18px;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 9.05pt;
            margin-right: 0pt;
            margin-bottom: 18.05pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 2;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular"
              >上海车赢信息技术有限公司推出的车况查询套餐包含两种类型：出险查询、事故查询。每个类型有规格不同的套餐可供选择。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 18.05pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 1gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 18px;
              "
              ><font face="PingFang SC Semibold">套餐使用规则</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 18px;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 9.05pt;
            margin-right: 0pt;
            margin-bottom: 9.05pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
            mso-list: l0 level1 lfo1;
          "
        >
          <span
            style="font-family: 'PingFang SC Regular'; font-size:14px"
            ><span style="mso-list: Ignore">1、</span></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular"
              >购买套餐后，即可用套餐查询对应类型的车况报告。若是机构购买套餐，则该机构下的系统用户都可以使用此套餐查询车况报告。若是门店购买的套餐，则该门店下的系统用户都可以使用此套餐查询车况报告。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 9.05pt;
            margin-right: 0pt;
            margin-bottom: 9.05pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            >2</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular">、</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular"
              >套餐使用有效期为自购买之日起三年内，套餐余量过期未使用将做自动放弃使用处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 18.05pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 1gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 18px;
              "
              ><font face="PingFang SC Semibold">售后说明</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 18px;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 9.05pt;
            margin-right: 0pt;
            margin-bottom: 9.05pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
            mso-list: l1 level1 lfo2;
          "
        >
          <span
            style="font-family: 'PingFang SC Regular'; font-size:14px"
            ><span style="mso-list: Ignore">1、</span></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular"
              >上海车赢信息技术有限公司推出的出险和事故查询套餐都适用一经购买，不退不改原则。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 9.05pt;
            margin-right: 0pt;
            margin-bottom: 9.05pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            >2</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular">、</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size:14px;
            "
            ><font face="PingFang SC Regular"
              >上海车赢信息技术有限公司保留对出险和事故查询套餐服务的最终解释权。</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size:14px;
              "
              ><o:p></o:p></span
          ></b>
        </p>
      </div>
      <!--EndFragment-->
    </body>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss'>
.ml_recharge {
  padding: 15px;
  p,
  span {
    color: #292d33 !important;
    line-height: 26px !important;
  }

  @font-face {
    font-family: "Times New Roman";
  }

  @font-face {
    font-family: "宋体";
  }

  @font-face {
    font-family: "Wingdings";
  }

  @font-face {
    font-family: "Calibri Light";
  }

  @font-face {
    font-family: "等线 Light";
  }

  @font-face {
    font-family: "Calibri";
  }

  @font-face {
    font-family: "等线";
  }

  @font-face {
    font-family: "PingFang SC Semibold";
  }

  @font-face {
    font-family: "PingFang SC Regular";
  }

  @list l0:level1 {
    mso-level-number-format: decimal;
    mso-level-suffix: none;
    mso-level-text: "%1、";
    mso-level-tab-stop: none;
    mso-level-number-position: left;
    margin-left: 0pt;
    text-indent: 0pt;
    font-family: "Times New Roman";
  }

  @list l1:level1 {
    mso-level-number-format: decimal;
    mso-level-suffix: none;
    mso-level-text: "%1、";
    mso-level-tab-stop: none;
    mso-level-number-position: left;
    margin-left: 0pt;
    text-indent: 0pt;
    font-family: "Times New Roman";
  }

  p.MsoNormal {
    mso-style-name: 正文;
    mso-style-parent: "";
    margin: 0pt;
    margin-bottom: 0.0001pt;
    font-family: Calibri;
    mso-fareast-font-family: 等线;
    mso-bidi-font-family: "Times New Roman";
    font-size:14px;
  }

  h1 {
    mso-style-name: "标题 1";
    mso-style-next: 正文;
    margin-top: 12pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    mso-outline-level: 1;
    font-family: "Calibri Light";
    mso-fareast-font-family: "等线 Light";
    mso-bidi-font-family: "Times New Roman";
    color: rgb(47, 84, 150);
    font-size: 16pt;
  }

  h2 {
    mso-style-name: "标题 2";
    mso-style-noshow: yes;
    mso-style-next: 正文;
    margin-top: 2pt;
    page-break-after: avoid;
    mso-pagination: lines-together;
    mso-outline-level: 2;
    font-family: "Calibri Light";
    mso-fareast-font-family: "等线 Light";
    mso-bidi-font-family: "Times New Roman";
    color: rgb(47, 84, 150);
    font-size: 13pt;
  }

  p.p {
    mso-style-name: "普通\(网站\)";
    mso-style-noshow: yes;
    margin-top: 5pt;
    margin-bottom: 5pt;
    mso-margin-top-alt: auto;
    mso-margin-bottom-alt: auto;
    mso-pagination: widow-orphan;
    text-align: left;
    font-family: 宋体;
    font-size:14px;
  }

  p.MsoFooter {
    mso-style-name: 页脚;
    mso-style-noshow: yes;
    margin: 0pt;
    margin-bottom: 0.0001pt;
    layout-grid-mode: char;
    font-family: Calibri;
    mso-fareast-font-family: 等线;
    mso-bidi-font-family: "Times New Roman";
    font-size: 9pt;
  }

  p.MsoCommentText {
    mso-style-name: 批注文字;
    mso-style-noshow: yes;
    margin: 0pt;
    margin-bottom: 0.0001pt;
    text-align: left;
    font-family: Calibri;
    mso-fareast-font-family: 等线;
    mso-bidi-font-family: "Times New Roman";
    font-size:14px;
  }

  p.MsoHeader {
    mso-style-name: 页眉;
    mso-style-noshow: yes;
    margin: 0pt;
    margin-bottom: 0.0001pt;
    border-bottom: 1pt solid windowtext;
    mso-border-bottom-alt: 0.75pt solid windowtext;
    padding: 0pt 0pt 1pt 0pt;
    layout-grid-mode: char;
    text-align: center;
    font-family: Calibri;
    mso-fareast-font-family: 等线;
    mso-bidi-font-family: "Times New Roman";
    font-size: 9pt;
  }


  span.msoIns {
    mso-style-type: export-only;
    mso-style-name: "";
    text-decoration: underline;
    text-underline: single;
    color: blue;
  }

  span.msoDel {
    mso-style-type: export-only;
    mso-style-name: "";
    text-decoration: line-through;
    color: red;
  }

  table.MsoNormalTable {
    mso-style-name: 普通表格;
    mso-style-parent: "";
    mso-style-noshow: yes;
    mso-tstyle-rowband-size: 0;
    mso-tstyle-colband-size: 0;
    mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
    mso-para-margin: 0pt;
    mso-para-margin-bottom: 0.0001pt;
    mso-pagination: widow-orphan;
    font-family: "Times New Roman";
    font-size: 10pt;
    mso-ansi-language: #0400;
    mso-fareast-language: #0400;
    mso-bidi-language: #0400;
  }
  @page {
    mso-page-border-surround-header: no;
    mso-page-border-surround-footer: no;
  }
  @page Section0 {
    margin-top: 72pt;
    margin-bottom: 72pt;
    margin-left: 72pt;
    margin-right: 72pt;
    size: 612pt 792pt;
    layout-grid: 18pt;
    mso-header-margin: 35.4pt;
    mso-footer-margin: 35.4pt;
  }
  div.Section0 {
    page: Section0;
  }
}
</style>